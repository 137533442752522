import React from 'react'
import "./Toolbar.css"
import { Link } from 'react-router-dom'
import bagImage from '../../images/shopping_bag.svg'
import accountImage from '../../images/account_circle.svg'
import logoLider1 from '../../images/logo_lider_1.jpg'
import logoLider2 from '../../images/logo_lider_2.png'
import logoLider3 from '../../images/logo_lider_3.jpg'

function Toolbar() {
    return (
        <div className="nav__root">

            <div className="tool1">
                <input type="checkbox" id="click" />
                <div className="tool1__part1">
                    <label htmlFor="click" className="burgerButton">
                        <span className="burgerButton__bar" />
                        <span className="burgerButton__bar" />
                        <span className="burgerButton__bar" />
                    </label>
                    <div className="nav__logo__container">
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        {/* <div className="nav__logo"></div> */}
                        <img className="nav__logo__image" src={logoLider1} alt="bag" />
                        <img className="nav__logo__image" src={logoLider2} alt="bag" />
                        <img className="nav__logo__image" src={logoLider3} alt="bag" />
                    </Link>
                    </div>

                </div>

                <div className="tool1__part3">
                    <div className="linkList">
                        <ul className="linkList__ul">
                            <li className="linkList__li"><Link to="/products" className="linkList__item">PRODUCTOS</Link></li>
                            <li className="linkList__li"><Link to="/" className="linkList__item">SERVICIOS</Link></li>
                            <li className="linkList__li"><Link to="#" className="linkList__item">CONTACTO</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="tool1__part4">
                    <img className="icon__images" src={bagImage} alt="bag" />
                    <img className="icon__images" src={accountImage} alt="account" />
                </div>
            </div>
        </div>
    )
}

export default Toolbar
