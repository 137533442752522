import React, { useContext, useState, useEffect, Fragment } from 'react'
import ProductContext from '../../../store/product-context';
import API from '../../../utils/API';
import Product from '../Product/Product';
import BikeNotFound from '../../../images/bike2.svg'
import "./ProductList.css"
import Loader from 'react-loader-spinner';
import SearchBar from '../../../Components/SearchBar/SearchBar';

function ProductList() {
    const productsCtx = useContext(ProductContext)
    const [isLoading, setIsLoading] = useState(false);
    const [isEmptyList, setIsEmptyList] = useState(false);
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        console.log(productsCtx.productInfo)
        loadProducts()
    }, [productsCtx.productInfo]);

    const loadProducts = () => {
        if (productsCtx.productInfo.search) {
            searchProducts()
        } else {
            getProducts()
        }
    }

    const getProducts = async () => {
        setIsLoading(true)
        try {
            if (productsCtx.productInfo.filter) {
                let response = await API.post("/catalog/bikes", productsCtx.productInfo.filterValue);
                updateProductsUI(response)
            } else {
                let response = await API.post("/catalog/bikes");
                updateProductsUI(response)
            }
        } catch (e) {
            showNotFoundProduct()
        }
    }

    const searchProducts = async () => {
        setIsLoading(true)
        const data = {
            "search": productsCtx.productInfo.searchValue
        }
        try {
            let response = await API.post("/catalog/bikes/search", data);
            console.log(response);
            updateProductsUI(response)
        } catch (e) {
            showNotFoundProduct()
        }
    }

    const updateProductsUI = (response) => {
        setIsLoading(false)
        if (response.status == 201) {
            if (response.data.length > 0) {
                setProductList(response.data)
                setIsEmptyList(false)
            } else {
                showNotFoundProduct()
            }
        } else {
            showNotFoundProduct()
        }
    }

    const showNotFoundProduct = () => {
        setIsLoading(false)
        setProductList([])
        setIsEmptyList(true)
    }

    return (
        <Fragment>
            {
                isLoading &&
                <div className="products__loader__container">
                    <Loader
                        type="Oval"
                        color="#256eff"
                        height={80}
                        width={80}
                    />
                    <p>Cargando Productos</p>
                </div>
            }
            {
                !isEmptyList && !isLoading &&
                <div class="product__list__container">
                    <SearchBar />
                    <div className="product-display-section">
                        {productList.map(product => (
                            <Product
                                id={product._id}
                                brand={product.brand}
                                title={product.name}
                                size={product.size}
                                code={product.code}
                                images={product.images}
                                imagesV2={product.imagesV2}>
                            </Product>
                        ))}
                    </div>
                </div>
            }
            {
                isEmptyList && !isLoading &&
                <div class="product__list__container">
                    <SearchBar />
                    <div className="products__loader__container">
                        <img src={BikeNotFound} alt="account" />
                        <p>No existen productos</p>
                    </div>
                </div>
            }
        </Fragment>

    )
}

export default ProductList
