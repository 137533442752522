import React, { useContext, useState, useEffect, useRef } from 'react'
import Toolbar from '../../../Components/Toolbar/Toolbar'
import "./ProductDetail.css"
import { useParams, useHistory } from "react-router-dom";
import API from '../../../utils/API';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ProductDetail() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [bike, setBike] = useState({});
    const { id } = useParams();
    const imgRef = useRef();
    const showcaseRef = useRef();

    useEffect(() => {
        loadProducts()
    }, []);

    const loadProducts = async () => {
        try {
            const url = `/bike/${id}`
            let response = await API.get(url)
            setBike(response.data)
        } catch (e) {
            console.log(e)
        }
    }

    const imageHandler = (value) => {
        if (imgRef.current != null && showcaseRef.current != null) {
            const displayWidth = imgRef.current.clientWidth;
            showcaseRef.current.style.transform = `translateX(${- (value - 1) * displayWidth}px)`;
        }
    }

    const getValidArrayOfImages = () => {
        if (bike.imagesV2 != null && bike.imagesV2.length > 0) {
            const newArray = bike.imagesV2.map((value, index) => (
                value['url']
            ));
            console.log(newArray)
            return newArray;
        } else if (bike.images != null && bike.images.length > 0) {
            return bike.images;
        }
        return [];
    }

    const containImages = () => {
        return (bike.imagesV2 != null && bike.imagesV2.length > 0) || (bike.images != null && bike.images.length > 0)
    }

    return (
        <div>
            <Toolbar />
            <ArrowBackIcon className="arrow__back" onClick={() => history.goBack()} />

            <div >
                <div className="card">
                    <div className="product-imgs">
                        <div className="img-display">
                            <div className="img-showcase" ref={showcaseRef}>
                                {
                                    containImages() &&
                                    getValidArrayOfImages().map(image => (
                                            <img src={image} className="img-detail" alt="bike" ref={imgRef}/>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="img-select">
                            {
                                containImages() &&
                                getValidArrayOfImages().map((image, index) => (
                                    <div className="img-item">
                                        <img src={image} className="img-detail" alt="bike" value="size-16" onClick={() => { imageHandler(index + 1) }} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="product-content">
                        {/* <h2 className="product-title">nike shoes</h2> */}
                        {bike.name && <h2 className="product-title">{bike.name}</h2>}
                        {/* <a href="#" className="product-link">visit nike store</a> */}
                        <div className="product-rating">
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star-half-alt"></i>
                            <span>4.7(21)</span>
                        </div>

                        {/* <div className="product-price">
                            <p className="last-price">Old Price: <span>$257.00</span></p>
                            <p className="new-price">New Price: <span>$249.00 (5%)</span></p>
                        </div> */}

                        <div className="product-detail">
                            <h2>Descripcion: </h2>
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo eveniet veniam tempora fuga tenetur placeat sapiente architecto illum soluta consequuntur, aspernatur quidem at sequi ipsa!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, perferendis eius. Dignissimos, labore suscipit. Unde.</p>
                             */}
                            {
                                bike.description &&
                                <p>{bike.description}</p>
                            }
                            <ul>
                                {
                                    bike.code &&
                                    <li>Codigo: <span>{bike.code}</span></li>
                                }
                                {
                                    bike.colors &&
                                    <li>Colores: <span>{bike.colors}</span></li>
                                }
                                {
                                    bike.colors &&
                                    <li>Estilo: <span>{bike.style}</span></li>
                                }
                                {
                                    bike.size &&
                                    <li>Tamaño: <span>{bike.size}</span></li>
                                }
                            </ul>
                        </div>

                        <div className="purchase-info">
                            <input type="number" min="0" defaultValue="1" />
                            <button type="button" className="btn">
                                Agregar al Carrito <i className="fas fa-shopping-cart"></i>
                            </button>
                            {/* <button type="button" className="btn">Compare</button> */}
                        </div>

                        <div className="social-links">
                            <p>Compartir: </p>
                            <a href="#">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="#">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="#">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="#">
                                <i className="fab fa-whatsapp"></i>
                            </a>
                            <a href="#">
                                <i className="fab fa-pinterest"></i>
                            </a>
                        </div>
                    </div>


                </div>

            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default ProductDetail
