import React from 'react'
import Toolbar from '../../Components/Toolbar/Toolbar'
import ProductFilter from './ProductsFilter/ProductFilter'
import "./Products.css"
import Footer from '../Footer/Footer'
import ProductList from './ProductList/ProductList'

function Products() {
    return (
        <div>
            <Toolbar />
            <div className="products" >
                <div className="products__filters">
                    <ProductFilter />
                </div>
                <ProductList />
            </div>
            <Footer />
        </div>
    )
}

export default Products
