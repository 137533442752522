import axios from "axios"


axios.interceptors.request.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
})

export default axios.create({
    //baseURL: "http://localhost:3100/",
    baseURL: "https://lider-bike-backend-v2.herokuapp.com",
    headers: {
        'Content-Type': 'application/json'
    }
});