import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from './Views/Home/Home';
import Products from './Views/Products/Products';
import ProductDetail from './Views/Products/ProductDetail/ProductDetail';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/products">
            <Products />
          </Route>
          <Route path="/productDetail/:id">
            <ProductDetail/>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
