import React from 'react'
import "./Header.css"
import { Link } from 'react-router-dom'
import headerImage from '../../../images/heroimage1.jpg'

function Header() {
    return (
        <div className="header__container">
            <div className="header__col_2">
                <h1>Bicicletas<br />Para toda la familia</h1>
                <p>En comercial el porteño encontraras una gran variedad de marcas<br/>colores y tamaños de bicicletas para todo tipo de actividades.</p>
                <Link to="/products"  style={{ textDecoration: 'none' }} className="btn">Explora ahora &#8594;</Link>
            </div>
            <div className="header__col_2">
                <img src={headerImage}/>
            </div>
        </div>
    )
}

export default Header