import React from 'react'
import "./Footer.css"
import { Col, Container, Row } from 'react-bootstrap'
import facebookIcon from '../../images/facebook_icon.svg'
import instagramIcon from '../../images/instagram_icon.svg'
import { Link } from 'react-router-dom'


function Footer() {
    return (
        <div>
            <div className="footer__root">
                <Container>
                    <Row>
                        <Col sm>
                            <div className="footer__section1">
                                <div className="footer__logo">LiderBike</div>
                                <div className="footer__text">TODOS LOS DERECHOS RESERVADOS</div>
                            </div>
                        </Col>
                        <Col sm>
                            <div className="footer__section2">
                                <div className="footer__text">Products</div>
                                <div className="footer__text">Servicios</div>
                                <div className="footer__text">Contacto</div>
                            </div> </Col>
                        <Col sm>
                            <div className="footer__section3">
                                <a href="https://www.facebook.com/ComercialElPorteno">
                                    <img className="footer__imageSocial" src={facebookIcon} alt="facebookIcon" />
                                </a>
                                <a href="https://www.facebook.com/ComercialElPorteno">
                                    <img className="footer__imageSocial" src={instagramIcon} alt="instagramIcon" />
                                </a>
                            </div> </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Footer
