import React, { useContext, useState } from "react";
import ProductContext from "../../store/product-context";
import "./SearchBar.css";

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const productsCtx = useContext(ProductContext);

    const searchProduct = () => {
        var searchValue = true;
        var filterValue = false
        let query = {};
        if(searchTerm == '') {
            searchValue = false
        }
        productsCtx.updateProductsState({
            search: searchValue,
            searchValue: searchTerm,
            filter: filterValue,
            filterValue: query
        })
    }

    const handleEnterKey = (event) => {
        if (event.key === "Enter") {
            searchProduct();
        }
    };

    const handleButtonClick = () => {
        searchProduct();
    };

    return (
        <div className="search-container">
            <input
                type="text"
                placeholder="Buscar productos"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                onKeyPress={handleEnterKey}
                className="search-input"
            />
            <button class="search-btn" onClick={handleButtonClick}>
                <i class="fas fa-search"></i>
            </button>
        </div>
    );
};

export default SearchBar;
