import React, { useState, useEffect } from 'react'
import Toolbar from '../../Components/Toolbar/Toolbar'
import Header from './Header/Header'
import "./Home.css"
import category1 from '../../images/category-4.jpg'
import category2 from '../../images/category-5.jpg'
import category3 from '../../images/category-6.jpg'
import vector1 from '../../images/vector1.svg'
import vector2 from '../../images/vector2.svg'
import vector3 from '../../images/vector3.svg'
import Product from '../Products/Product/Product'
import Footer from '../Footer/Footer'
import FeaturedSection from './Featured/FeaturedSection'

function Home() {
    return (
        <div>
            <Toolbar />
            <Header />

            <div classNameName="categories">
                <div className="small__container">
                    <h2 className="title">Categorias Destacados</h2>
                    <div className="row">
                        <div className="col-3">
                            <img src={category1} />
                        </div>
                        <div className="col-3">
                            <img src={category2} />
                        </div>
                        <div className="col-3">
                            <img src={category3} />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br /><br /><br /><br />
            <div className="small__container2">
                <h2 className="title">Productos Destacados</h2>
                <div className="product-display-section">
                    <FeaturedSection />
                </div>
            </div>
            <br /><br /><br /><br /><br /><br />
            <div className="small__container2">
                <div className="row" style={{ marginTop: '40px', marginBottom: '40px' }}>
                    <div className="col-sm-4" style={{ alignContent: 'center' }}>
                        <img src={vector1} style={{ width: '120px', height: '120px', display: 'block', margin: 'auto' }} />
                        <div className="home__poductTitle">Garantía</div>
                        <div className="home__productText">Bicicletas con un año de garantía.</div>
                    </div>

                    <div className="col-sm-4" style={{ alignContent: 'center' }}>
                        <img src={vector3} style={{ width: '120px', height: '120px', display: 'block', margin: 'auto' }} />
                        <div className="home__poductTitle">Experiencia</div>
                        <div className="home__productText">Contamos con más de 25 años de experiencia en bicicletas.</div>
                    </div>

                    <div className="col-sm-4" style={{ alignContent: 'center' }}>
                        <img src={vector2} style={{ width: '120px', height: '120px', display: 'block', margin: 'auto' }} />
                        <div className="home__poductTitle">Importadores</div>
                        <div className="home__productText">Somos importadores directos de bicicletas y repuestos.</div>
                    </div>
                </div>
            </div>
            <br /><br /><br /><br /><br /><br />
            <Footer />
        </div>
    )
}

export default Home
