import React, { useState, useEffect } from 'react';

const ProductContext = React.createContext({
    productInfo: {},
    updateProductsState: (info) => { },
});

export const ProductContextProvider = (props) => {
    const [productInfo, setProductInfo] = useState({
        search: false,
        searchValue: "",
        filter: false,
        filterValue: {}
    })

    const updateProductInforHandler = (info) => {
        setProductInfo(info)
    }

    return (
        <ProductContext.Provider
            value={{
                productInfo: productInfo,
                updateProductsState: updateProductInforHandler,
            }}>
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductContext