import React, { useContext, useState, useEffect, Fragment } from 'react'
import API from '../../../utils/API';
import Product from '../../Products/Product/Product';
import "./FeaturedSection.css"

function FeaturedSection() {
    const [isLoading, setIsLoading] = useState(false);
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        loadProducts()
    }, []);

    const loadProducts = async () => {
        setIsLoading(true)
        try {
            let response = await API.post("/catalog/bikes/featured", {});
            console.log(response)
            updateProductsUI(response)
        } catch (e) {
            showNotFoundProduct()
        }
    }

    const updateProductsUI = (response) => {
        setIsLoading(false)
        if (response.status == 200) {
            if (response.data.length > 0) {
                if(response.data.length <= 3) {
                    setProductList(response.data)
                }else {
                    let newArray = [response.data[0], response.data[1], response.data[2]]
                    setProductList(newArray)
                }
                //setIsEmptyList(false)
            } else {
                showNotFoundProduct()
            }
        } else {
            showNotFoundProduct()
        }
    }

    const showNotFoundProduct = () => {
        // setIsLoading(false)
        // setProductList([])
        // setIsEmptyList(true)
    }

    return (
        <Fragment>
            {
                productList.map(product => (
                    <Product
                        id={product._id}
                        brand={product.brand}
                        title={product.name}
                        size={product.size}
                        code={product.code}
                        images={product.images}
                        imagesV2={product.imagesV2}>
                    </Product>
                ))
            }
        </Fragment>
    )
}

export default FeaturedSection