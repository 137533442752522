import React from 'react'
import { Link } from 'react-router-dom'
import "./Product.css"

function Product({ id, title, images, size, code, brand, imagesV2 }) {
    const detailLink = `/productDetail/${code}`

    const getImageUrl = () => {
        var url = ''
        if (imagesV2 != null && imagesV2.length > 0) {
            const jsonValue = imagesV2[0]
            url = jsonValue['url']
        } else if (images != null && images.length > 0){
            url = images[0]
        }
        return url
    }

    return (
        <Link to={detailLink} style={{ textDecoration: 'none' }}>
            <div className="product__card">
                <img src={getImageUrl()} alt="" />
                <div className="product__info">
                    <p className="product__name">{title}</p>
                    <p className="product__brand">{brand}</p>
                    <p className="product__size">Tamaño: {size}</p>
                </div>
            </div>
        </Link>
    )
}

export default Product