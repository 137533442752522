import React, { useContext, useState, useEffect } from 'react'
import ProductContext from '../../../store/product-context'
import "./ProductFilter.css"

function ProductFilter() {
    const [sizeFilter, setSizeFilter] = useState([]);
    const [brandFilter, setBrandFilter] = useState([]);
    const [genderFilter, setGenderFilter] = useState([]);
    const productsCtx = useContext(ProductContext)

    const handleChange = (event) => {
        let separator = event.target.value.indexOf("-")
        let filterType = event.target.value.substring(0, separator)
        let value = event.target.value.substring(separator + 1, event.target.length)

        let sizeArray = [...sizeFilter]
        if (filterType == "size") {
            if (event.target.checked) {
                sizeArray.push(value)
                setSizeFilter(sizeArray)
            } else {
                const index = sizeArray.indexOf(value);
                if (index > -1) {
                    sizeArray.splice(index, 1);
                    setSizeFilter(sizeArray)
                }
            }
        }

        let brandArray = [...brandFilter]
        if(filterType == "brand") {
            if (event.target.checked) {
                brandArray.push(value)
                setBrandFilter(brandArray)
            } else {
                const index = brandArray.indexOf(value);
                if (index > -1) {
                    brandArray.splice(index, 1);
                    setBrandFilter(brandArray)
                }
            }
        }

        let genderArray = [...genderFilter]
        if(filterType == "gender") {
            if (event.target.checked) {
                genderArray.push(value)
                setGenderFilter(genderArray)
            } else {
                const index = genderArray.indexOf(value);
                if (index > -1) {
                    genderArray.splice(index, 1);
                    setGenderFilter(genderArray)
                }
            }
        }


        let query = {};
        if(sizeArray.length > 0) {
            query.size = sizeArray;
        }  
        if(brandArray.length > 0) {
            query.brand = brandArray;
        }  
        if(genderArray.length > 0) {
            query.gender = genderArray 
        }
        console.log(query)
        productsCtx.updateProductsState({
            search: false,
            searchValue: "",
            filter: true,
            filterValue: query
        })
    };

    return (
        <div onChange={handleChange}>
            <p className="product_filter__name">Tamaño</p>

            <label className="container__filter">
                12
                <input type="checkbox" value="size-12" />
                <span className="checkmark" />
            </label>

            <label className="container__filter">
                16
                <input type="checkbox" value="size-16" />
                <span className="checkmark" />
            </label>

            <label className="container__filter">
                20
                <input type="checkbox" value="size-20" />
                <span className="checkmark" />
            </label>

            <label className="container__filter">
                24
                <input type="checkbox" value="size-24" />
                <span className="checkmark" />
            </label>
            <label className="container__filter">
                26
                <input type="checkbox" value="size-26" />
                <span className="checkmark" />
            </label>

            <p className="product_filter__name">Marca</p>

            <label className="container__filter">
                LiderBike
                <input type="checkbox" value="brand-Liderbike" />
                <span className="checkmark" />
            </label>

            <label className="container__filter">
                Diamondbike
                <input type="checkbox" value="brand-Diamondbike" />
                <span className="checkmark" />
            </label>

            <label className="container__filter">
                Starlight
                <input type="checkbox" value="brand-Starlight" />
                <span className="checkmark" />
            </label>
            <label className="container__filter">
                Virus
                <input type="checkbox" value="brand-Virus" />
                <span className="checkmark" />
            </label>
            <label className="container__filter">
                Hiland
                <input type="checkbox" value="brand-Hiland" />
                <span className="checkmark" />
            </label>
            <label className="container__filter">
                Totem
                <input type="checkbox" value="brand-Totem" />
                <span className="checkmark" />
            </label>
            <label className="container__filter">
                Joystar
                <input type="checkbox" value="brand-Joystar" />
                <span className="checkmark" />
            </label>

            <p className="product_filter__name">Tipo</p>

            <label className="container__filter">
                Niño
                <input type="checkbox" value="gender-Niño" />
                <span className="checkmark" />
            </label>

            <label className="container__filter">
                Niña
                <input type="checkbox" value="gender-Niña" />
                <span className="checkmark" />
            </label>
        </div>
    )
}

export default ProductFilter
